import { Component, Injectable } from '@angular/core';
import { Http } from '@angular/http';

@Injectable()
export class UploadService {
  constructor(private http: Http) { }

  public upload = function (fileToUpload: any, uploadUrl) {

    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http.post(uploadUrl, input);
  }

}
