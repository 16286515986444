import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-Page-Top',
  templateUrl: './Page-Top.component.html',
  styleUrls: ['../Master/master.component.css']
})
export class PageTopComponent implements OnInit {

  @Input() valuesFromParent: any

  constructor() { }

  ngOnInit() {
  }
}
