import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTopComponent } from './Page-Top.component';
import { RouterModule } from '@angular/router';
import { TypeaheadPopupDirective } from './Directives/popup.directive'
@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [PageTopComponent,TypeaheadPopupDirective],
  exports : [PageTopComponent,TypeaheadPopupDirective]
})
export class CommonUIModuleModule { }
